import schttp from 'public/src/services/schttp'

//获取多语言
export const getMessageLanguageApi = (params, useBffApi) => {
  if (useBffApi) {
    return schttp({
      method: 'POST',
      url: '/system/configs/page_multi_language_mapping',
      data: params,
      useBffApi
    })
  }
  return schttp({
    url: '/api/user/message/language/get'
  })
}
